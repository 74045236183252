/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content {
  margin: 2vw;
}
.back-style {
  margin-top: 3vw;
  justify-content: center;
  text-align: center;
  padding-bottom: 2vw;
}
.back {
  font-size: 1.5em;
}
